import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetAllListingsByListingTourQuery,
  useUpdateListingMutation,
  useLazyDownloadPhotographQuery,
} from "../../../services/listings";
import { useUpdateListingTourOrderMutation } from "../../../services/listings-on-the-tours";
import { useGetAllTierMembersQuery } from "../../../services/member";
import { AuthContext } from "../../../context/AuthContext";
// import { DataGrid } from "@mui/x-data-grid";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import {
  IconButton,
  Tooltip,
  Paper,
  Typography,
  Divider,
  Box,
  Button,
} from "@mui/material";
import {
  Edit,
  Flag as FlagIcon,
  FlagOutlined as FlagOutlinedIcon,
} from "@mui/icons-material/";
import CreateEditListingForm from "../../reosadmin/listing-tour/CreateEditListingForm";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CommentsEditCell from "./listings/CommentsEditCell";
import ESPEditCell from "./listings/ESPEditCell";
import AgentEditCell from "./listings/AgentEditCell";
import CreateEditListingTourDialog from "./CreateEditListingTourDialog";
import AddListingsToListingTOurDialog from "./AddListingsToListingTOurDialog";

const ListingTourDataGridList = ({ listingTour, listingTours = [] }) => {
  const navigate = useNavigate();
  const { userRole } = useContext(AuthContext);
  const { data: listingsData, refetch } = useGetAllListingsByListingTourQuery(
    {
      tierId: listingTour?.tier?._id,
      listingTourId: listingTour?._id,
    },
    {
      skip: !listingTour?.tier?._id || !listingTour?._id,
    }
  );
  const [pastListings, setPastListings] = useState([]);
  const [tourListings, setTourListings] = useState([]);
  const [comingListings, setComingListings] = useState([]);
  const { data: members } = useGetAllTierMembersQuery(
    { tierId: listingTour?.tier?._id },
    { skip: !listingTour?.tier?._id }
  );
  const pastApiRef = useGridApiRef();
  const tourApiRef = useGridApiRef();
  const comingApiRef = useGridApiRef();
  const [trigger, { data: downloadedPhotograph }] =
    useLazyDownloadPhotographQuery();
  const [photographUrls, setPhotographUrls] = useState({});
  const [updateListing, { isLoading: isUpdating }] = useUpdateListingMutation();
  const [updateListingTourOrder] = useUpdateListingTourOrderMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [createListingDialogOpen, setCreateListingDialogOpen] = useState(false);
  const [addListingsToTourDialogOpen, setAddListingsToTourDialogOpen] =
    useState(false);
  const [addListingsToTourDialogMode, setAddListingsToTourDialogMode] =
    useState("add");
  const editableRoles = [
    "reosadmin",
    "reossuperadmin",
    "brokeragesuperadmin",
    "brokerageadmin",
    "brokerage",
  ];
  const [editListingTourDialogOpen, setEditListingTourDialogOpen] =
    useState(false);
  const isEditable = editableRoles?.includes(userRole);
  const [pastSelectionModel, setPastSelectionModel] = useState([]);
  const [tourSelectionModel, setTourSelectionModel] = useState([]);
  const [comingSelectionModel, setComingSelectionModel] = useState([]);
  const updateListingInArray = (array, id, updatedData) => {
    return array.map((item) =>
      item?._id === id ? { ...item, ...updatedData } : item
    );
  };
  const findAndUpdateListing = (id, updatedData) => {
    if (pastListings?.some((item) => item?._id === id)) {
      setPastListings((prev) => updateListingInArray(prev, id, updatedData));
      return "past";
    } else if (tourListings?.some((item) => item?._id === id)) {
      setTourListings((prev) => updateListingInArray(prev, id, updatedData));
      return "tour";
    } else if (comingListings?.some((item) => item?._id === id)) {
      setComingListings((prev) => updateListingInArray(prev, id, updatedData));
      return "coming";
    }
    return null;
  };

  useEffect(() => {
    if (listingsData) {
      const past = (listingsData.pastListings || [])?.map((listing) => ({
        ...listing,
        id: listing?._id,
      }));

      const tour = (listingsData.tourListings || [])?.map((listing) => ({
        ...listing,
        id: listing?._id,
      }));

      const coming = (listingsData.comingListings || [])?.map((listing) => ({
        ...listing,
        id: listing?._id,
      }));

      setPastListings(past);
      setTourListings(tour);
      setComingListings(coming);
    }
  }, [listingsData]);
  console.log("listingsData", listingsData);
  useEffect(() => {
    if (tourListings && tourListings.length > 0) {
      tourListings?.forEach((listing) => {
        if (listing.isGroup) return;
        const firstPhoto = listing?.photographs?.[0];
        if (firstPhoto && !photographUrls[firstPhoto?._id]) {
          trigger({
            photographId: firstPhoto?._id,
            disposition: "photoghraphs",
          }).then((result) => {
            if (result?.data?.url) {
              setPhotographUrls((prev) => ({
                ...prev,
                [firstPhoto?._id]: result?.data?.url,
              }));
            }
          });
        }
      });
    }
    if (pastListings && pastListings.length > 0) {
      pastListings?.forEach((listing) => {
        if (listing.isGroup) return;
        const firstPhoto = listing?.photographs?.[0];
        if (firstPhoto && !photographUrls[firstPhoto?._id]) {
          trigger({
            photographId: firstPhoto?._id,
            disposition: "photoghraphs",
          }).then((result) => {
            if (result?.data?.url) {
              setPhotographUrls((prev) => ({
                ...prev,
                [firstPhoto?._id]: result?.data?.url,
              }));
            }
          });
        }
      });
    }
    if (comingListings && comingListings.length > 0) {
      comingListings?.forEach((listing) => {
        if (listing.isGroup) return;
        const firstPhoto = listing?.photographs?.[0];
        if (firstPhoto && !photographUrls[firstPhoto?._id]) {
          trigger({
            photographId: firstPhoto?._id,
            disposition: "photoghraphs",
          }).then((result) => {
            if (result?.data?.url) {
              setPhotographUrls((prev) => ({
                ...prev,
                [firstPhoto?._id]: result?.data?.url,
              }));
            }
          });
        }
      });
    }
  }, [tourListings, comingListings, pastListings, trigger, photographUrls]);

  const handleCreateListingDialogOpen = () => {
    setCreateListingDialogOpen(true);
  };

  const handleCreateListingDialogClose = () => {
    setCreateListingDialogOpen(false);
  };

  const handleAddListingsToTourDialogOpen = () => {
    setAddListingsToTourDialogMode("add");
    setAddListingsToTourDialogOpen(true);
  };

  const handleRemoveListingsFromTourDialogOpen = () => {
    setAddListingsToTourDialogMode("remove");
    setAddListingsToTourDialogOpen(true);
  };

  const handleAddListingsToTourDialogClose = () => {
    setAddListingsToTourDialogMode("");
    setAddListingsToTourDialogOpen(false);
  };

  const handleAgentChange = async (id, agent) => {
    if (agent) {
      const formData = new FormData();
      const submissionData = {
        agent: agent?._id,
      };
      formData.append("listingData", JSON.stringify(submissionData));

      const result = await updateListing({
        tierId: listingTour?.tier?._id,
        listingId: id,
        formData,
      });

      if (result?.data) {
        findAndUpdateListing(id, { agent });
        setNotification({
          open: true,
          message: `Agent Field: ${result?.data?.msg}`,
        });
        refetch?.();
      } else {
        setNotification({
          open: true,
          message: `Error updating agent: ${
            result?.error?.data?.msg || "Unknown error"
          }`,
        });
      }
    }
  };

  const handleCommentChange = async (id, updatedComments) => {
    const allListings = [...pastListings, ...tourListings, ...comingListings];
    const row = allListings?.find((row) => row?._id === id);

    const existingComments = row?.comments || [];

    const allComments = [...existingComments, ...updatedComments];

    if (allComments && allComments?.length > 0) {
      const formData = new FormData();
      const submissionData = {
        comments: allComments,
      };
      formData.append("listingData", JSON.stringify(submissionData));

      const result = await updateListing({
        tierId: listingTour?.tier?._id,
        listingId: id,
        formData,
      });
      if (result?.data) {
        const gridType = findAndUpdateListing(id, {
          comments: allComments,
        });
        if (gridType === "past") {
          pastApiRef.current.stopCellEditMode({ id, field: "comments" });
        } else if (gridType === "tour") {
          tourApiRef.current.stopCellEditMode({ id, field: "comments" });
        } else if (gridType === "coming") {
          comingApiRef.current.stopCellEditMode({ id, field: "comments" });
        }

        setNotification({
          open: true,
          message: `Comment Added: ${result?.data?.msg}`,
        });
        setTimeout(() => {
          refetch?.();
        }, 500);
      } else {
        setNotification({
          open: true,
          message: `Error adding comment: ${
            result?.error?.data?.msg || "Unknown error"
          }`,
        });
      }
    }
  };

  const handleESPChange = async (id, updatedESPs) => {
    const allListings = [...pastListings, ...tourListings, ...comingListings];
    const row = allListings?.find((row) => row?._id === id);

    const existingESPs = row?.estimatedSellingPrice || [];

    const allESPs = [...existingESPs, ...updatedESPs];

    if (allESPs && allESPs?.length > 0) {
      const formData = new FormData();
      const submissionData = {
        estimatedSellingPrice: allESPs,
      };
      formData.append("listingData", JSON.stringify(submissionData));

      const result = await updateListing({
        tierId: listingTour?.tier?._id,
        listingId: id,
        formData,
      });
      if (result?.data) {
        const gridType = findAndUpdateListing(id, {
          estimatedSellingPrice: updatedESPs,
        });

        if (gridType === "past") {
          pastApiRef.current.stopCellEditMode({
            id,
            field: "estimatedSellingPrice",
          });
        } else if (gridType === "tour") {
          tourApiRef.current.stopCellEditMode({
            id,
            field: "estimatedSellingPrice",
          });
        } else if (gridType === "coming") {
          comingApiRef.current.stopCellEditMode({
            id,
            field: "estimatedSellingPrice",
          });
        }
        setNotification({
          open: true,
          message: `Estimated Price Field: ${result?.data?.msg}`,
        });
        setTimeout(() => {
          refetch?.();
        }, 500);
      } else {
        setNotification({
          open: true,
          message: `Error adding Estimated Price: ${
            result?.error?.data?.msg || "Unknown error"
          }`,
        });
      }
    }
  };

  const columns = [
    {
      field: "photographs",
      headerName: "icon",
      width: 100,
      renderCell: (params) => {
        const firstPhoto = params.row?.photographs?.[0];
        const photoUrl = photographUrls[firstPhoto?._id];

        const listingId = params.row?._id;

        const handleClick = () => {
          navigate(`/listing/${listingId}`);
        };

        return (
          <Tooltip title="View Listing Details">
            <img
              src={photoUrl || "/imgs/Home-img-coming-soon.jpg"}
              alt="Photograph"
              style={{
                width: 50,
                height: 50,
                objectFit: "cover",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={handleClick}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "isForListingTour",
      headerName: "For Tour",
      width: 50,
      renderCell: (params) => {
        const isForTour = params.row?.isForListingTour;

        return (
          <>
            {isForTour ? (
              <FlagIcon color="primary" />
            ) : (
              <FlagOutlinedIcon color="disabled" />
            )}
          </>
        );
      },
    },
    {
      field: "tourComments",
      headerName: "Tour Comments",
      width: 300,
      valueGetter: (value, row) => {
        return row?.tourComments ? row?.tourComments.join("\n") : "";
      },
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", lineHeight: "1.5" }}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      width: 200,
    },
    {
      field: "listPrice",
      headerName: "List Price",
      width: 100,
      valueGetter: (value, row) => (row?.listPrice ? `$${row?.listPrice}` : ""),
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "listingDate",
      headerName: "Listing Date",
      width: 150,
      valueGetter: (value, row) => {
        return row?.listingDate
          ? new Date(row?.listingDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : "";
      },
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "bedrooms",
      headerName: "Bedrooms",
      width: 75,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "bathrooms",
      headerName: "Bathrooms",
      width: 75,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "totalSqft",
      headerName: "Total (sqft)",
      width: 100,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "finishedSqft",
      headerName: "Finished (sqft)",
      width: 100,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "lotSqft",
      headerName: "Lot (sqft)",
      width: 100,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "lotAcres",
      headerName: "Lot Acres",
      width: 100,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "mls",
      headerName: "MLS",
      width: 75,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "area",
      headerName: "Area",
      width: 110,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "subarea",
      headerName: "Subarea",
      width: 150,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "openTime",
      headerName: "Open Time",
      width: 150,
      valueGetter: (value, row) =>
        `${
          row?.openTime && !row?.openTime?.includes("undefined")
            ? row?.openTime
            : "Not limited"
        }`,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "lockboxLocation",
      headerName: "Lockbox Location",
      width: 150,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "agent",
      headerName: "Agent",
      width: 200,
      editable: isEditable,
      valueGetter: (value, row) => {
        return row.agent
          ? `${row?.agent?.firstName} ${row?.agent?.lastName}`
          : "";
      },
      renderEditCell: (params) => {
        return (
          <AgentEditCell
            params={params}
            handleAgentChange={handleAgentChange}
            members={members}
          />
        );
      },
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "listingComment",
      headerName: "Listing Comments",
      width: 300,
      valueGetter: (value, row) => {
        return row?.listingComment ? row?.listingComment.join("\n") : "";
      },
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", lineHeight: "1.5" }}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 300,
      editable: isEditable,
      valueFormatter: (value, row) => {
        if (!row?.comments || !Array.isArray(row?.comments)) {
          return "";
        }
        return row?.comments
          .map(
            (comment) =>
              `${comment?.user?.firstName || ""} ${
                comment?.user?.lastName?.charAt(0) || ""
              }: ${comment?.comment || ""}`
          )
          .join("; ");
      },
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", lineHeight: "1.5" }}>
            {params.formattedValue}
          </div>
        );
      },
      renderEditCell: (params) => {
        return (
          <CommentsEditCell
            params={params}
            handleCommentChange={handleCommentChange}
          />
        );
      },
    },
    {
      field: "estimatedSellingPrice",
      headerName: "Estimated Selling Price",
      width: 300,
      editable: isEditable,
      valueFormatter: (value, row) => {
        if (
          !row?.estimatedSellingPrice ||
          !Array.isArray(row?.estimatedSellingPrice)
        ) {
          return "";
        }
        return row?.estimatedSellingPrice
          ?.map(
            (esp) =>
              `${esp?.user?.firstName || ""} ${
                esp?.user?.lastName?.charAt(0) || ""
              }: $${esp?.estimatedPrice || ""}`
          )
          ?.join("; ");
      },
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", lineHeight: "1.5" }}>
            {params.formattedValue}
          </div>
        );
      },
      renderEditCell: (params) => {
        return (
          <ESPEditCell params={params} handleESPChange={handleESPChange} />
        );
      },
    },
    {
      field: "videoLinks",
      headerName: "Video Links",
      width: 250,
      editable: isEditable,
      valueGetter: (value, row) => {
        return row?.videoLinks ? row?.videoLinks.join("\n") : "";
      },
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", lineHeight: "1.5" }}>
            {params.value}
          </div>
        );
      },
    },
  ];

  const handleRowOrderChange = async (params) => {
    const { oldIndex, targetIndex } = params;

    const updatedTourListings = [...tourListings];
    const [movedRow] = updatedTourListings.splice(oldIndex, 1);
    updatedTourListings.splice(targetIndex, 0, movedRow);
    setTourListings(updatedTourListings);

    const reorderedData = updatedTourListings.map((row, index) => ({
      _id: row?._id,
      order: index,
    }));

    if (reorderedData && reorderedData?.length > 0) {
      const result = await updateListingTourOrder({
        tierId: listingTour?.tier?._id,
        listingTourId: listingTour?._id,
        listings: reorderedData,
      });

      if (result?.data) {
        setNotification({
          open: true,
          message: `Row Reorder: ${result?.data?.msg}`,
        });
        refetch?.();
      } else {
        setNotification({
          open: true,
          message: `Error reorder: ${
            result?.error?.data?.msg || "Unknown error"
          }`,
        });
      }
    }
  };

  const handleEditListingTourDialogOpen = () => {
    setEditListingTourDialogOpen(true);
  };

  const handleEditListingTourDialogClose = () => {
    setEditListingTourDialogOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };
  // console.log("");
  return (
    <div>
      <Paper sx={{ p: 1, mb: 2, display: "flex", flexDirection: "column" }}>
        <Box
          sx={{ p: 2, mt: 1, display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h6" align="center" gutterBottom>
            {`"${listingTour?.listingTourTitle?.toUpperCase()}"
            Listing Tour - 
            ${new Date(listingTour?.listingTourDate)?.toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "short",
                day: "numeric",
              }
            )}`}
          </Typography>
          <Tooltip title="EDIT LISTING TOUR">
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditListingTourDialogOpen}
              startIcon={<Edit />}
            >
              EDIT
            </Button>
          </Tooltip>
        </Box>
        <Divider />
        <Box
          sx={{ p: 0, mt: 1, display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateListingDialogOpen}
          >
            Create New Listing
          </Button>
          <Box>
            <Button
              variant="contained"
              color="success"
              onClick={handleAddListingsToTourDialogOpen}
              disabled={
                !pastSelectionModel.length && !comingSelectionModel.length
              }
              sx={{ mr: 1 }}
            >
              Add Selected Listings to the Tour
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleRemoveListingsFromTourDialogOpen}
              disabled={!tourSelectionModel.length}
            >
              Remove Selected Listings from the Tour
            </Button>
          </Box>
        </Box>
      </Paper>
      <div style={{ maxHeight: 550, width: "100%" }}>
        <Typography variant="h6">Past Listings</Typography>
        <DataGridPro
          apiRef={pastApiRef}
          rows={pastListings}
          columns={columns}
          sx={{ height: 500 }}
          getRowId={(row) => row.id}
          getRowHeight={() => "auto"}
          checkboxSelection
          disableSelectionOnClick
          rowSelectionModel={pastSelectionModel}
          onRowSelectionModelChange={(newSelection) => {
            setPastSelectionModel(newSelection);
            console.log("setPastSelectionModel Rows:", newSelection);
          }}
        />
      </div>
      {(!pastListings || pastListings?.length === 0) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 2,
          }}
        >
          <Typography variant="h5" gutterBottom>
            No Past Listings available
          </Typography>
        </Box>
      )}
      <div style={{ maxHeight: 550, width: "100%" }}>
        <Typography variant="h6">Tour Listings</Typography>
        <DataGridPro
          apiRef={tourApiRef}
          rows={tourListings}
          columns={columns}
          sx={{ height: 500 }}
          rowReordering
          onRowOrderChange={handleRowOrderChange}
          getRowId={(row) => row.id}
          getRowHeight={() => "auto"}
          checkboxSelection
          disableSelectionOnClick
          rowSelectionModel={tourSelectionModel}
          onRowSelectionModelChange={(newSelection) => {
            setTourSelectionModel(newSelection);
          }}
        />
      </div>
      {(!tourListings || tourListings?.length === 0) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 2,
          }}
        >
          <Typography variant="h5" gutterBottom>
            No Tour Listings available
          </Typography>
        </Box>
      )}
      <div style={{ maxHeight: 550, width: "100%" }}>
        <Typography variant="h6">Coming Soon Listings</Typography>
        <DataGridPro
          apiRef={comingApiRef}
          rows={comingListings}
          columns={columns}
          sx={{ height: 500 }}
          getRowId={(row) => row.id}
          getRowHeight={() => "auto"}
          checkboxSelection
          disableSelectionOnClick
          rowSelectionModel={comingSelectionModel}
          onRowSelectionModelChange={(newSelection) => {
            setComingSelectionModel(newSelection);
          }}
        />
      </div>
      {(!comingListings || comingListings?.length === 0) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 2,
          }}
        >
          <Typography variant="h5" gutterBottom>
            No Coming Soon Listings available
          </Typography>
        </Box>
      )}
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <CreateEditListingForm
        open={createListingDialogOpen}
        onClose={handleCreateListingDialogClose}
        mode="create"
        tierId={listingTour?.tier?._id}
        listingTours={listingTours}
      />
      <CreateEditListingTourDialog
        tier={listingTour?.tier}
        open={editListingTourDialogOpen}
        onClose={handleEditListingTourDialogClose}
        mode="edit"
        listingTour={listingTour}
      />
      <AddListingsToListingTOurDialog
        open={addListingsToTourDialogOpen}
        onClose={handleAddListingsToTourDialogClose}
        tierId={listingTour?.tier?._id}
        listingIds={
          addListingsToTourDialogMode === "add"
            ? [...pastSelectionModel, ...comingSelectionModel]
            : tourSelectionModel
        }
        listingTours={listingTours}
        refetch={refetch}
        mode={addListingsToTourDialogMode}
        listingTourId={listingTour?._id}
      />
    </div>
  );
};

export default ListingTourDataGridList;
